import { veterinary } from '@/axios'

export default {
  acceptDoctor (id) {
    return veterinary().patch(`admin/doctors/${id}`, {
      status: 'accepted'
    })
  },
  acceptClinic (id) {
    return veterinary().patch(`admin/clinics/${id}`, {
      status: 'accepted'
    })
  },
  getSettings () {
    return veterinary().get('admin/settings')
  },
  postSettings (data) {
    return veterinary().post('admin/settings', data)
  },
  getDoctorData (id) {
    return veterinary().get(`admin/doctors/${id}`)
  },
  acceptDoctorDocumnets (id) {
    return veterinary().patch(`admin/doctors/${id}`, { status: 'accepted' })
  },
  getRequiredDocuments () {
    return veterinary().get('acceptance-docs?required_for=doctors')
  },
  uploadDotorDocument (payload) {
    return veterinary().post('upload', payload)
  },
  addDoctor (payload) {
    return veterinary().post('clinics/add-doctor', payload)
  }
}
